import { Link } from "react-router-dom";

// import logo from '../../../assets/images/logo.png';
// import logo from '../../../assets/images/Henderson.png';
// import logo from '../../../assets/images/henderson2.png';
// import logo from '../../../assets/images/henderson3.png';
// import logo from '../../../assets/images/henderson4.png';
// import logo from '../../../assets/images/henderson5.png';
// import logo from '../../../assets/images/henderson6.png';
// import logo from '../../../assets/images/henderson7.png';
// import logo from '../../../assets/images/henderson8.png';
// import logo from '../../../assets/images/henderson9.png';
import logo from '../../../assets/images/henderson10.png';

const Logo = () => {
    return (
        <div className="logo-outer d-flex align-items-center">
            <div className="logo">
                <Link to={"/"}>
                    <img src={logo} alt={'Envotek'} />
                </Link>
            </div>
        </div>
    );
}

export default Logo;