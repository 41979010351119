const ResellerSectionTitle = ({ label, title, extraClass }) => {
    return (
        <div className={`section-titlere ${extraClass ? extraClass : ""}`}>
            <span>{label}</span>
            <h4>{title}</h4>
            <p>
                {
                    "We resell for some of the world’s leading technology companies, such as Microsoft, VMware, Citrix, Palo Alto Networks and Cisco. This gives us access to their expertise, helping us provide better-informed service to our customers.  All products purchased from Ingram Micro and Dickerdata include all standard manufacturer warranties."
                }
            </p>
        </div>
    );
};

export default ResellerSectionTitle;
