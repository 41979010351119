import React, { useEffect } from "react";

import AboutSection from "../../Utilities/AboutSection";
import AboutChooseUsSection from "../../Utilities/AboutSection/AboutChooseUsSection";
import CallToAction from "../../Utilities/CallToAction";
import ClientsSection from "../../Utilities/ClientsSection";
import PageBanner from "../../Utilities/PageBanner";
import TeamSection from "../../Utilities/TeamSection";
import AboutSection2 from "../../Utilities/AboutSection/AboutSection2";

const About = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    return (
        <React.Fragment>
            <PageBanner pageTitle={"Who We Are"} currentPage={"About"} />
            <AboutSection />
            <AboutSection2 />
            {/* <AboutChooseUsSection /> */}
            {/* <ClientsSection />
            <TeamSection /> */}
            <CallToAction extraClass={"bg-white"} />
        </React.Fragment>
    );
};

export default About;
