import React, { useEffect } from "react";
import CallToAction from "../../Utilities/CallToAction";
import PageBanner from "../../Utilities/PageBanner";
import ServiceDetailsSection from "../../Utilities/ServiceDetailsSection/ServiceDetailsSection";
import TeamSection from "../../Utilities/TeamSection";
import DisasterRecoveryDetails from "../../Utilities/ServiceDetailsSection/DisasterRecoveryDetails";
import DisasterClients from "../../Utilities/ClientsSection/DisasterClientsSection";
import DisasterProject from "../ProjectDetails/DisasterProject";
import CloudSolutionDetails from "../../Utilities/ServiceDetailsSection/CloudSolutionDetails";
import DisasterBanner from "../../Utilities/PageBanner/DisasterBanner";

const DisasterRecovery = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <React.Fragment>
            {/* <PageBanner
                pageTitle={'Disaster Recovery'}
                currentPage={'Disaster Recovery'}
            /> */}
            <DisasterBanner
                pageTitle={"Disaster Recovery"}
                currentPage={"Disaster Recovery"}
            />
            <CloudSolutionDetails />

            <DisasterClients />
            {/* <DisasterProject /> */}

            {/* <DisasterRecoveryDetails /> */}

            <CallToAction />
            {/* <TeamSection/> */}
        </React.Fragment>
    );
};

export default DisasterRecovery;
