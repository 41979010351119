import { Link } from "react-router-dom";

import post1 from "../../../assets/images/footer/post1.png";
import post2 from "../../../assets/images/footer/post2.png";
import MessengerCustomerChat from "react-messenger-customer-chat";

const footerPost = [
    {
        thumb: post1,
        title: "We carry more than just good coding skills.",
        date: "22.01.2021",
    },
    {
        thumb: post2,
        title: "We carry more than just good coding skills.",
        date: "22.01.2021",
    },
];

const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="footer-wrap py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-center align-items-center">
                                {/* <h6 className="mb-0">{"Quick Links"}</h6> */}
                                <ul className="d-flex quick-link-custom">
                                    <h6 className="mb-0 text-start ms-4 mt-1">
                                        {"Quick Links"}
                                    </h6>
                                    {/* <li className="ms-3">
                                        <Link
                                            to={"/"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            {"Home"}
                                        </Link>
                                    </li> */}
                                    <li className="ms-3">
                                        <Link
                                            to={"/cloudsolution"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            {"Cloud Solutions"}
                                        </Link>
                                    </li>
                                    <li className="ms-3">
                                        <Link
                                            to={"/recovery"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            {"Disaster Recovery"}
                                        </Link>
                                    </li>
                                    <li className="ms-3">
                                        <Link
                                            to={"/itsupport"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            {"Onsite IT Support"}
                                        </Link>
                                    </li>
                                    <li className="ms-3">
                                        <Link
                                            to={"/about"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            Who We Are
                                        </Link>
                                    </li>
                                    <li className="ms-3">
                                        <Link
                                            to={"/contact"}
                                            className="text-secondary"
                                        >
                                            <i className="icofont-double-right"></i>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget latest-post">
                                <h4>{"Latest Post"}</h4>
                                {footerPost.map((data, index) => (
                                    <div className="post-item" key={index}>
                                        <div className="post-image">
                                            <Link to={"/blog-details"}>
                                                <img
                                                    src={data.thumb}
                                                    alt={"Latest Post Image"}
                                                />
                                            </Link>
                                        </div>
                                        <div className="post-content">
                                            <h6>
                                                <Link to={"/blog-details"}>
                                                    {data.title}
                                                </Link>
                                            </h6>
                                            <span>{data.date}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget quick-links support">
                                <h4>{"Support"}</h4>
                                <ul>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Forum"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Help & FAQ"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Pricing"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Cookies Policy"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Help & FAQ"}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget quick-links company">
                                <h4>{"Company"}</h4>
                                <ul>
                                    <li>
                                        <Link to={"/about"}>
                                            <i className="icofont-double-right"></i>
                                            {"About Us"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Team"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Case Studies"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/contact"}>
                                            <i className="icofont-double-right"></i>
                                            {"Locations"}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"}>
                                            <i className="icofont-double-right"></i>
                                            {"Members"}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <MessengerCustomerChat
                        pageId="110581785301992"
                        appId="887997113107887"
                    />
                </div>
            </div>
            {/* <!-- Copyright Area --> */}
            <div className="copy-right-area">
                <div className="container">
                    <div className="copy-right-text text-center">
                        <p>
                            <span>{"Copyright"}</span>{" "}
                            {"© 2024 | All Rights Reserved"}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
