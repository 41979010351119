import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Link } from "react-scroll";

import About from "./Pages/AboutPage";
import BlogDetails from "./Pages/BlogDetails";
import ContactUs from "./Pages/ContactPage";
import HomePage from "./Pages/HomePage";
import ProjectDetails from "./Pages/ProjectDetails";
import ServiceDetails from "./Pages/ServiceDetails";
import Footer from "./Utilities/Footer";
import Header from "./Utilities/Header";
import CloudSolution from "./Pages/ServiceDetails/CloudSolution";
import DisasterRecovery from "./Pages/ServiceDetails/DisasterRecovery";
import OnsiteITSupport from "./Pages/ServiceDetails/OnsiteITSupport";
import BlogSection from "./Utilities/BlogSection";

const App = () => {
    return (
        <Router>
            <div className="page-wrapper">
                <Header />

                <Switch>
                    <Route exact path={"/"} component={HomePage} />
                    <Route exact path={"/about"} component={About} />
                    <Route
                        exact
                        path={"/service-details"}
                        component={ServiceDetails}
                    />
                    <Route
                        exact
                        path={"/project-details"}
                        component={ProjectDetails}
                    />
                    <Route
                        exact
                        path={"/blog-details"}
                        component={BlogDetails}
                    />
                    <Route exact path={"/contact"} component={ContactUs} />

                    <Route
                        exact
                        path={"/cloudsolution"}
                        component={CloudSolution}
                    />
                    <Route
                        exact
                        path={"/recovery"}
                        component={DisasterRecovery}
                    />
                    <Route
                        exact
                        path={"/itsupport"}
                        component={OnsiteITSupport}
                    />

                    <Route exact path={"/feture"} component={BlogSection} />
                </Switch>

                <Footer />
            </div>
        </Router>
    );
};

export default App;
