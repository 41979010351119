import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../Logo";
import { NavDropdown } from "react-bootstrap";

const Header = () => {
    const [services, setServices] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });

    const isSticky = () => {
        const header = document.querySelector(".main-header");
        const scrollTop = window.scrollY;
        scrollTop >= 250
            ? header.classList.add("fixed-header")
            : header.classList.remove("fixed-header");
    };

    const [mobileMenu, setMobileMenu] = useState(false);
    const isMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    return (
        <header className="main-header">
            <div className="header-upper">
                <div className="container clearfix">
                    <div className="header-inner d-lg-flex align-items-center">
                        <Logo />
                        <div className="nav-outer clearfix ml-lg-auto mr-lg-auto">
                            <nav className="main-menu navbar-expand-lg">
                                <div className="navbar-header clearfix">
                                    <button
                                        onClick={isMobileMenu}
                                        type="button"
                                        className="navbar-toggle"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse"
                                    >
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>
                                <div
                                    className={`navbar-collapse collapse clearfix ${
                                        mobileMenu ? "show" : ""
                                    }`}
                                >
                                    <ul className="navigation clearfix">
                                        <li className="dropdown">
                                            <span
                                                className={`nav-link no-underline dropdown-toggle ${
                                                    services ? "active" : ""
                                                }`}
                                                style={{ fontWeight: "700" }}
                                            >
                                                Our Services
                                                <div className="dropdown-menu">
                                                    <NavLink
                                                        onClick={() => {
                                                            closeMobileMenu();
                                                            setServices(true);
                                                        }}
                                                        to={"/cloudsolution"}
                                                        className="nav-link no-underline"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {"Cloud Solutions"}
                                                    </NavLink>
                                                    <NavLink
                                                        onClick={() => {
                                                            closeMobileMenu();
                                                            setServices(true);
                                                        }}
                                                        to={"/recovery"}
                                                        className="nav-link no-underline"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {"Disaster Recovery"}
                                                    </NavLink>
                                                    <NavLink
                                                        onClick={() => {
                                                            closeMobileMenu();
                                                            setServices(true);
                                                        }}
                                                        to={"/itsupport"}
                                                        className="nav-link no-underline"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {"Onsite IT Support"}
                                                    </NavLink>
                                                </div>
                                            </span>
                                        </li>
                                        <li>
                                            <NavLink
                                                onClick={() => {
                                                    closeMobileMenu();
                                                    setServices(false);
                                                }}
                                                className="nav-link no-underline"
                                                to={"/about"}
                                                activeClassName="active"
                                            >
                                                {"Who we are"}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                onClick={() => {
                                                    closeMobileMenu();
                                                    setServices(false);
                                                }}
                                                className="nav-link no-underline"
                                                exact
                                                to={"/feture"}
                                                activeClassName="active"
                                            >
                                                {"What It Cost"}
                                            </NavLink>
                                            {/* <a
                                                href="#feature"
                                                className="btn theme-btn"
                                            >
                                                {"What It Cost"}
                                                <i className="icofont-double-right"></i>
                                            </a> */}
                                        </li>

                                        <li>
                                            <NavLink
                                                onClick={() => {
                                                    closeMobileMenu();
                                                    setServices(false);
                                                }}
                                                className="nav-link no-underline"
                                                to={"/contact"}
                                                activeClassName="active"
                                            >
                                                {"Contact Us"}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        {/* <div style={{ display: 'inline-block' }} className="menu-btn">
                            <div>
                                <i className="icofont-location-pin" style={{ fontSize: '50px', color: '#fc5445' }}></i>
                            </div>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <h6 style={{  marginLeft: '10px' }}>0429 999 329</h6>
                            <h6 style={{  marginLeft: '24px' }}>Perth, WA</h6>
                        </div> */}
                        <div className="menu-btn">
                            <div style={{ display: "inline-block" }}>
                                <div>
                                    <i
                                        className="icofont-location-pin"
                                        style={{
                                            fontSize: "50px",
                                            color: "#fc5445",
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div style={{ display: "inline-block" }}>
                                <h6 style={{ marginLeft: "10px" }}>
                                    0429 999 329
                                </h6>
                                <h6 style={{ marginLeft: "24px" }}>
                                    Perth, WA
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
