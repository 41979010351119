import Sidebar from "./Sidebar";
import detailsImg from "../../../assets/images/service/service-details.jpg";
import workImg1 from "../../../assets/images/service/work-img1.png";
import workImg2 from "../../../assets/images/service/work-img2.png";

const CloudSolutionDetails = () => {
    return (
        <section className="service-details pt-120 rpt-100 pb-115 rpb-95">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-4">
                        <Sidebar/>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="service-details-content">
                            <div className="work-part-content">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="work-left">
                                            <div className="work-image">
                                                <img
                                                    src={workImg1}
                                                    alt="Work Image"
                                                />
                                            </div>
                                            <div className="work-image-two">
                                                <img
                                                    src={workImg2}
                                                    alt="Work Image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="work-content">
                                            <h4>{"Assist and Facilitate"}</h4>
                                            <h6>
                                                {
                                                    "Assist and facilitate with redundant BACKUP strategies to keep always available copies of company data in case of accidental damages on the machines, hacker attacks, virus infections either in cloud or offline onsite media."
                                                }
                                            </h6>
                                            <h4>{"We can also help with,"}</h4>
                                            {/* <p>{"Hard Drive and RAID Upgrades"}</p> */}
                                            <ul
                                                style={{
                                                    listStyleType: "disc",
                                                    margin: "15px",
                                                }}
                                            >
                                                <li>
                                                    Hard Drive and RAID Upgrades
                                                </li>
                                                <li>
                                                    Server fails to start
                                                    (degraded or failed raid
                                                    storage)
                                                </li>
                                                <li>
                                                    Desktop/ Laptop/ Server
                                                    storage data recovery
                                                </li>
                                            </ul>

                                            {/* <p>{"Hard Drive and RAID Upgrades"}</p> */}
                                            {/* <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ind ustry's standard dummy text ever since the 1500s, when an unknown printer took and galley of type and scrambled it to make a type specimen book."}</p>
                                            <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ind ustry's standard dummy."}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>{"Reliable data backup"}</h4>
                            <p>
                                {
                                    "Reliable data backup strategically backs up your data to protect against system failures, cyber-attacks etc. To ensure that your confidential business data remains confidential, we exist. With backup and replication using VEEAM, we can protect your company data and replicate data in cloud or offsite data vault"
                                }
                            </p>
                            {/* <blockquote>{"Hard Drive and RAID Upgrades"}</blockquote>
                            <blockquote>{"Server fails to start (degraded or failed raid storage)"}</blockquote>
                            <blockquote>{"Desktop/ Laptop/ Server storage data recovery"}</blockquote> */}
                            <p>
                                {
                                    "If necessary or in an emergency, all critical data can be easily restored. With smart additional functions, you can also "
                                }
                                <p>
                                    {
                                        "access and control your data from anywhere in the world."
                                    }
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CloudSolutionDetails;
