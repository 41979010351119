import { IoMdArrowDropright } from "react-icons/io";
import aboutImg1 from "../../../assets/images/about/about-img1.jpg";
import aboutImg2 from "../../../assets/images/about/about-img2.jpg";
import SectionTitle from "../SectionTitle";

const AboutSection2 = () => {
    return (
        <section className="about-us pt-120 rpt-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us-content">
                            <SectionTitle
                                // label={"Promotion"}
                                title={"Our Products and Services"}
                            />

                            <h5>List of Products and Services</h5>
                            <ul>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    System Integration
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Data Recovery
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    New Laptop/Desktop supply and installation
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Windows server maintenance anf installation
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Network maintenance
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Hardware supply and installation
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Health Scanning and Optimizations
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Software supply, installation maintenance
                                    and Logistics
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    WiFi Network configuration and signal range
                                    extending
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Home Security Video over IP/WiFi
                                    installation and configuration
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Business relocation
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-left">
                            {/* <div className="experience-years">
                                <h1>{"25+"}</h1>
                                <h4>{"Years Experience"}</h4>
                            </div> */}
                            <div className="about-image">
                                <img src={aboutImg1} alt="About Image" />
                            </div>
                            <div className="about-image-two">
                                <img src={aboutImg2} alt="About Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection2;
