import contactImg from "../../../assets/images/get-touch-img.png";
const ContactInfo = () => {
    return (
        <div
            className="d-flex justify-content-evenly align-items-center"
            style={{
                background: "linear-gradient(to top, #ffddda, #ffffff)",
                padding: "50px",
            }}
        >
            <div>
                <div className="info-box">
                    <div className="info-icon">
                        <i className="icofont-google-map"></i>
                    </div>
                    <div className="info-details">
                        <p>{"Beeliar, Perth, Western Australia, 6164"}</p>
                    </div>
                </div>
                <div className="info-box">
                    <div className="info-icon">
                        <i className="icofont-phone"></i>
                    </div>
                    <div className="info-details">
                        <a href="callto:0429999329">{"0429 999 329"}</a>
                        {/* <a href="callto:88999333555">{"+88-999-333-555"}</a> */}
                    </div>
                </div>
                <div className="info-box">
                    <div className="info-icon">
                        <i className="icofont-email"></i>
                    </div>
                    <div className="info-details">
                        <a href="mailto:info@hendersonitsolutions.com.au">
                            {"info@hendersonitsolutions.com.au"}
                        </a>
                        {/* <a href="mailto:support@domain.com">{"support@domain.com"}</a> */}
                    </div>
                </div>
            </div>
            <div className="">
                <img src={contactImg} alt={"Get Touch Image"} />
            </div>
        </div>
    );
};

export default ContactInfo;
