import SectionTitle from "../SectionTitle";

// import thumb1 from '../../../assets/images/project/project1.jpg';
// import thumb2 from '../../../assets/images/project/project2.jpg';
// import thumb3 from '../../../assets/images/project/project3.jpg';
// import thumb4 from '../../../assets/images/project/project4.jpg';
// import thumb5 from '../../../assets/images/project/project5.jpg';
// import thumb6 from '../../../assets/images/project/project6.jpg';

// import rightImg from '../../../assets/images/project/project7.jpg'

import thumb1 from "../../../assets/images/project/project11.jpg";
import thumb2 from "../../../assets/images/project/project22.jpg";
import thumb3 from "../../../assets/images/project/project33.jpg";
import thumb4 from "../../../assets/images/project/project44.jpg";
import thumb5 from "../../../assets/images/project/project55.jpg";
import thumb6 from "../../../assets/images/project/project66.jpg";

import rightImg from "../../../assets/images/project/project77.jpg";

import { Link } from "react-router-dom";
import ResellerSectionTitle from "../SectionTitle/SectionTitleReseller";

const project = [
    {
        thumb: thumb1,
        title: "Windows Server, Microsoft Cloud, Microsoft Security, Azure, Microsoft 365, Microsoft Teams, Small Business",
    },
    {
        thumb: thumb2,
        title: "Next-Generation Firewalls Cloud-Delivered Security Services End Point Protection",
    },
    {
        thumb: thumb3,
        title: "PowerEdge servers, Laptops, Desktops, 2-in-1 Laptops, All-in-One Desktops, Gaming Laptops, Gaming Desktops",
    },
    {
        thumb: thumb4,
        title: "ThinkPad and Yoga Laptops, ThinkCentre Desktops, Full HD and 4K Monitors",
    },
    {
        thumb: thumb5,
        title: "Data Backup & Recovery Software Solutions",
    },
    {
        thumb: thumb6,
        title: "Rack Servers, Meraki MX security and SD-WAN appliances, Wireless access points",
    },
];

const ProjectSection = () => {
    return (
        <section className="project-section pt-110 rpt-90 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Section Title */}
                        <ResellerSectionTitle
                            // label={'Authorised Reseller'}
                            title={"Authorised Reseller"}
                            extraClass={"text-center"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            {project.map((data, index) => (
                                <div className="col-md-6" key={index}>
                                    <div className="project-item">
                                        <div className="project-image">
                                            <img
                                                src={data.thumb}
                                                alt={data.title}
                                            />
                                        </div>
                                        <div className="project-content">
                                            {/* <Link to={"/project-details"} className="project-icon">
                                                    <i className="icofont-arrow-right"></i>
                                                </Link> */}
                                            <h6>{data.title}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="project-item project-right-item"
                            style={{ background: `url(${rightImg})` }}
                        >
                            {/* <div className="project-content">
                                <Link to={"/project-details"} className="project-icon">
                                    <i className="icofont-arrow-right"></i>
                                </Link>
                                <h5><Link to={"/project-details"}>{"Product Consultation."}</Link></h5>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectSection;
