import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";

import thumb1 from "../../../assets/images/service/serv-icon1C.png";
import thumb2 from "../../../assets/images/service/serv-icon2C.png";
import thumb3 from "../../../assets/images/service/serv-icon3C.png";
import thumb4 from "../../../assets/images/service/serv-icon4C.png";
import thumb5 from "../../../assets/images/service/serv-icon5C.png";
import thumb6 from "../../../assets/images/service/serv-icon6.png";
// import thumb1 from '../../../assets/images/service/scal-icon1.png';

import serviceImg from "../../../assets/images/service/service-imgC.jpg";

const services = [
    {
        thumb: thumb1,
        title: "Scalability & flexibility",
        desc: "The ability to increase or decrease IT resources as needed to meet changing demand.",
    },
    {
        thumb: thumb2,
        title: "Cost savings",
        desc: "Reduced spending on storage, networking, security operational costs, maintenance, and upgrade expenses.",
    },
    {
        thumb: thumb3,
        title: "Better collaboration",
        desc: 'Cloud collaboration enables people to work simultaneously on documents that live "in the cloud" – so you can access files from anywhere with an Internet connection.',
    },
    {
        thumb: thumb4,
        title: "Advanced security",
        desc: "Cloud security ensure the authentication of users and devices, access control for data and resources, and protection of data privacy.",
    },
    {
        thumb: thumb5,
        title: "Data loss prevention",
        desc: `Cloud data is typically protected through methods such as backups, cloud storage, and disaster recovery—all of which are meant to ensure that data remains within an organization's possession in the event of a malware breach, data loss, or another event that would exploit the vulnerability of cloud data.`,
    },
    // {
    //     thumb: thumb6,
    //     title: 'Big Data And Analytics.',
    // },
];

const CloudServiceSection = () => {
    return (
        <section
            className="service-section pt-110 rpt-90 pb-70 rpb-100"
            id="feature"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Section Title */}
                        <SectionTitle
                            // label={"Cloud Solutions"}
                            title={"Benefits of Cloud Computing"}
                            extraClass={"text-center"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-9">
                        <div className="row">
                            {services.map((data, index) => (
                                <div className="col-sm-12" key={index}>
                                    {/* <Link to={data.url}> */}
                                    <div className="service-item">
                                        <div
                                            className="serv-iconimg"
                                            style={{
                                                width: "50px" /* Set the desired width */,
                                                flexShrink: 0,
                                            }}
                                        >
                                            <img
                                                src={data.thumb}
                                                alt={"Service Icon"}
                                            />
                                        </div>
                                        <div className="serv-content">
                                            {/* <h4>
                                                    <img src={data.thumb} alt={"Service Icon"} />{data.title}
                                                </h4> */}
                                            <h5>{data.title}</h5>
                                            <p>{data.desc}</p>
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div className="serv-iconimg">
                                                <img src={data.thumb} alt={"Service Icon"} />
                                            </div> */}
                                        <div>
                                            {/* <h4>
                                                    <img src={data.thumb} alt={"Service Icon"} />{data.title}
                                                </h4> */}
                                            {/* <h4>
                                                    {data.title}
                                                </h4> */}
                                            {/* <p>
                                                    {data.desc}
                                                </p> */}
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-3">
                        <div
                            className="service-img"
                            style={{ background: `url(${serviceImg})` }}
                        >
                            <div className="service-details-btn">
                                {/* <Link to={"/service-details"} className="btn theme-btn">
                                    {"More Services"}
                                    <i className="icofont-double-right"></i>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CloudServiceSection;
