import React, { useEffect } from "react";
import CallToAction from "../../Utilities/CallToAction";
import PageBanner from "../../Utilities/PageBanner";
import ServiceDetailsSection from "../../Utilities/ServiceDetailsSection/ServiceDetailsSection";
import TeamSection from "../../Utilities/TeamSection";
import OnSiteITSupportDetails from "../../Utilities/ServiceDetailsSection/OnsiteItSupport";
import ItSuppoerProjectDetails from "../ProjectDetails/ItSupportProject";
import OnsiteISupportProjectSection from "../../Utilities/RelatedProjectSection/OnsiteItSupportProjectSection";
import OnsiteItBanner from "../../Utilities/PageBanner/OnsiteItBanner";

const OnsiteITSupport = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <React.Fragment>
            {/* <PageBanner
                pageTitle={'OnSIte IT Support'}
                currentPage={'Support'}
            /> */}
            <OnsiteItBanner
                pageTitle={"Onsite IT Support"}
                currentPage={"Onsite It Support"}
            />

            <ItSuppoerProjectDetails />
            {/* <OnsiteISupportProjectSection /> */}

            {/* <OnSiteITSupportDetails /> */}

            {/* <CallToAction/> */}
            {/* <TeamSection/> */}
        </React.Fragment>
    );
};

export default OnsiteITSupport;
