const ServiceSectionTitle = ({ label, title, extraClass }) => {
    return (

        <div className={`section-title ${extraClass ? extraClass : ''}`}>
            <h6 style={{textAlign: 'center',color: '#FC5546'}}>{label}</h6>
            <br></br>
            <h4 style={{textAlign: 'center'}}>{title}</h4>
            <br></br>
        </div>

    );
}

export default ServiceSectionTitle;