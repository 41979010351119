import { IoMdArrowDropright } from "react-icons/io";
import aboutImg1 from "../../../assets/images/about/about-img1.jpg";
import aboutImg2 from "../../../assets/images/about/about-img2.jpg";
import SectionTitle from "../SectionTitle";

const AboutSection = () => {
    return (
        <section className="about-us pt-120 rpt-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-left">
                            {/* <div className="experience-years">
                                <h1>{"25+"}</h1>
                                <h4>{"Years Experience"}</h4>
                            </div> */}
                            <div className="about-image">
                                <img src={aboutImg1} alt="About Image" />
                            </div>
                            <div className="about-image-two">
                                <img src={aboutImg2} alt="About Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-us-content">
                            <SectionTitle
                                // label={"Promotion"}
                                title={"CALL FOR A FREE CONSULTATION"}
                            />

                            <p>
                                We provide professional services to individuals
                                and companies all accross Wester Austraila. Our
                                team will help you with any problems you have
                                regarding IT in a fast and efficient manner
                            </p>

                            <h5>
                                We can help with ongoing supports which includes
                            </h5>
                            <ul>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Remote and onsite tech support
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Proactive Monitoring
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Fine tune Business Applications
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Data Backup and Recovery
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Regular Technology Business Reviews
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
