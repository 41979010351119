import SectionTitle from "../SectionTitle";
import { IoMdArrowDropright } from "react-icons/io";
import { Row, Col } from "react-bootstrap";

import thumb1 from "../../../assets/images/blog/blog1.jpg";
import thumb2 from "../../../assets/images/blog/blog2.jpg";
import thumb3 from "../../../assets/images/blog/blog3.jpg";
import thumb4 from "../../../assets/images/blog/blog4.jpg";
import { Link } from "react-router-dom";

const posts = [
    {
        thumb: thumb1,
        date: "22 January, 2021",
        title: "It uses a dictionary of over combined handful.",
        excerpt:
            "It has survived not only five centuries typesetting remaining essentially was the release more recent.",
    },
    {
        thumb: thumb2,
        date: "22 January, 2021",
        title: "Efficiently monetize models transparent sources.",
        excerpt:
            "It has survived not only five centuries typesetting remaining essentially was the release more recent.",
    },
    {
        thumb: thumb3,
        date: "22 January, 2021",
        title: "Market Insights To Manage People Related Costs.",
        excerpt:
            "It has survived not only five centuries typesetting remaining essentially was the release more recent.",
    },
    {
        thumb: thumb4,
        date: "22 January, 2021",
        title: "New Remote Workers Visible To Security.",
        excerpt:
            "It has survived not only five centuries typesetting remaining essentially was the release more recent.",
    },
];

const BlogSection = () => {
    return (
        <section className="blog-section pt-110 rpt-90 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={"IT Support pricing"}
                            extraClass={"text-center"}
                        />
                    </div>
                </div>

                <Row style={{ marginBottom: "60px" }}>
                    <Col md={6}>
                        <div
                            style={{
                                padding: "10px",
                                background:
                                    "linear-gradient(to right, #FFDDDA, #FFFFFF)",
                                textAlign: "left",
                                height: "220px",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <h6>Ad hoc IT Support</h6>
                                <h4>$80 per hour</h4>
                                <p
                                    style={{
                                        color: "#453f4a",
                                        fontSize: "16px",
                                        marginBottom: "0",
                                    }}
                                >
                                    Minimum two hours billable.
                                    <br />
                                    Charged in 15-minute increments.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div
                            style={{
                                padding: "10px",
                                background:
                                    "linear-gradient(to right, #FFDDDA, #FFFFFF)",
                                textAlign: "left",
                                height: "220px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <h6>Managed IT Support</h6>
                                <h4>From $25 per user, per month</h4>
                                <p
                                    style={{
                                        color: "#453f4a",
                                        fontSize: "16px",
                                        marginBottom: "0",
                                    }}
                                >
                                    Contact us to discuss more flexible options
                                    to suit your needs.
                                    <br />
                                    Our IT support pricing for ongoing managed
                                    support is cost-effective and transparent,
                                    so you know exactly what you are getting for
                                    your money.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="">
                            <h5>Hardware and software we help clients with</h5>
                            <ul>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Servers (on premises or hosted)
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Managed firewall
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Workstations
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Office 365 tenant management
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Managed network devices, network switch and
                                    wireless access points
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Managed network storage devices
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    VoiP (voice over internet protocol) support
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Mobile device management
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="">
                            <h5>
                                We can help with ongoing supports which includes
                            </h5>
                            <ul>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Remote and onsite tech support
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Proactive Monitoring
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Fine tune Business Applications
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Data Backup and Recovery
                                </li>
                                <li>
                                    <IoMdArrowDropright className="me-2" />
                                    Regular Technology Business Reviews
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                {/* <div className="row">
                    {
                        posts.map((data, index) => (

                            <div className="col-lg-6" key={index}>
                                <div className="blog-item">
                                    <div className="blog-image" style={{ background: `url(${data.thumb})` }}></div>
                                    <div className="blog-content">
                                        <span className="date">{data.date}</span>
                                        <h5><Link to={"/blog-details"}>{data.title}</Link></h5>
                                        <p>{data.excerpt}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                </div> */}
            </div>
        </section>
    );
};

export default BlogSection;
