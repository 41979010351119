import { Link } from "react-router-dom";
import projectImg1 from "../../../assets/images/project/project1.jpg";
import projectImg2 from "../../../assets/images/project/project2.jpg";
import projectImg3 from "../../../assets/images/project/project4.jpg";
const rProject = [
    {
        id: "1",
        thumb: projectImg1,
        title: "IT Security",
        desc: "Is my current router/firewall sufficient or do I need to do more to protect my data?",
    },
    {
        id: "2",
        thumb: projectImg2,
        title: "Stability",
        desc: "How can I ensure that my systems are always highly available and cost effective?",
    },
    {
        id: "3",
        thumb: projectImg3,
        title: "Latest technologies",
        desc: `I want to keep up with the times, but I don't know which way is the right one?`,
    },
];
const OnsiteISupportProjectSection = () => {
    return (
        <div className="related-project">
            {/* <h4 className="text-center">{"Related Project"}</h4> */}
            <div className="row">
                {rProject.map((data, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                        <div className="project-item">
                            {/* <div className="project-image">
                                    <img src={data.thumb} alt={data.title} />
                                </div> */}
                            <div className="project-content">
                                {/* <Link to={"/project-details"} className="project-icon"> */}
                                <i className="icofont-arrow-right"></i>
                                {/* </Link> */}
                                <h5>
                                    {/* <Link to={"/project-details"}> */}
                                    {data.title}
                                    {/* </Link> */}
                                </h5>
                            </div>
                            <div className="project-image">
                                {/* <img src={data.thumb} alt={data.title} /> */}
                                <h6 className="fw-normal mt-3">{data.desc}</h6>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OnsiteISupportProjectSection;
