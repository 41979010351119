import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";

import thumb1 from "../../../assets/images/service/serv-icon1.png";
import thumb2 from "../../../assets/images/service/serv-icon2.png";
import thumb3 from "../../../assets/images/service/serv-icon3.png";
import thumb4 from "../../../assets/images/service/serv-icon4.png";
import thumb5 from "../../../assets/images/service/serv-icon5.png";
import thumb6 from "../../../assets/images/service/serv-icon6.png";

import serviceImg from "../../../assets/images/service/service-imgN.jpg";
import ServiceSectionTitle from "../SectionTitle/ServiceSectionTitle";

const services = [
    {
        thumb: thumb1,
        title: "Cloud Solution",
        url: "/cloudsolution",
    },
    {
        thumb: thumb2,
        title: "Disaster Recovery",
        url: "/recovery",
    },
    {
        thumb: thumb3,
        title: "Onsite IT Support",
        url: "/itsupport",
    },
    // {
    //     thumb: thumb4,
    //     title: 'Cyber Security Services.',
    // },
    // {
    //     thumb: thumb5,
    //     title: 'Software Development.',
    // },
    // {
    //     thumb: thumb6,
    //     title: 'Big Data And Analytics.',
    // },
];

const ServiceSection = () => {
    return (
        <section
            className="service-section pt-110 rpt-90 pb-70 rpb-100"
            id="feature"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Section Title */}
                        {/* <SectionTitle
                            label={'Our Services'}
                            title={'Choose Service to Manage Your Business'}
                            extraClass={'text-center'}
                        /> */}
                        <ServiceSectionTitle
                            // label={"Choose a Service"}
                            title={"Choose a Service"}
                            extraClass={"text-center"}
                        />
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-lg-8 col-xl-9"> */}
                    <div className="row">
                        {services.map((data, index) => (
                            <div className="col-sm-4" key={index}>
                                <Link to={data.url}>
                                    <div className="service-item">
                                        <div className="serv-iconimg">
                                            <img
                                                src={data.thumb}
                                                alt={"Service Icon"}
                                            />
                                        </div>
                                        <div className="serv-content">
                                            <h5>{data.title}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                    {/* </div> */}
                    {/* <div className="col-lg-4 col-xl-3">
                        <div className="service-img" style={{ background: `url(${serviceImg})` }}>
                            <div className="service-details-btn">
                                <Link to={"/service-details"} className="btn theme-btn">
                                    {"More Services"}
                                    <i className="icofont-double-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default ServiceSection;
