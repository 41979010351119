import SectionTitle from "../SectionTitle";
import chooseusimg from "../../../assets/images/chooseus/chooseus-imgN.jpg";
import quotation from "../../../assets/images/chooseus/quotation-icon.png";
import ChooseUsSectionTitle from "../SectionTitle/ChooseUsSectionTitle";
import { FaHandPointRight } from "react-icons/fa";

const ChooseusSection = () => {
    return (
        <section className="chooseus-section pt-120 rpt-100 pb-120 rpb-95">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="chooseus-left-content">
                            <div className="experience-years">
                                {/* <h1>{'25+'}</h1>
                                <h4>{"Years Experience"}</h4> */}
                            </div>
                            <div className="chooseus-img">
                                <img src={chooseusimg} alt="Choose Us Image" />
                            </div>
                            <div className="experience-content">
                                <blockquote>
                                    {
                                        "Elevate your business with our unparalleled IT services, setting new standards in reliability, innovation, and client satisfaction."
                                    }
                                </blockquote>
                                <div className="quotation-icon">
                                    <img src={quotation} alt="Quotation" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="chooseus-right-content">
                            {/* <SectionTitle
                                label={'Why Choose Us!'}
                                title={'Why You Should Choose Us!'}
                            /> */}
                            <ChooseUsSectionTitle
                                // label={'Why Choose Us!'}
                                title={"Why You Should Choose Us!"}
                            />
                            {/* <div className="chooseus-list">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <i className="icofont-check"></i>
                                                {"Simplify Communication"}
                                            </li>
                                            <li>
                                                <i className="icofont-check"></i>
                                                {"Eliminate Repeat Entry"}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <i className="icofont-check"></i>
                                                {"24/7 Customer Service"}
                                            </li>
                                            <li>
                                                <i className="icofont-check"></i>
                                                {"Expert Team Members"}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <p style={{ textAlign: "justify" }}>
                                <FaHandPointRight
                                    className=" me-2"
                                    style={{
                                        fontSize: "25px",
                                        color: "#fc5445",
                                    }}
                                />
                                <b>
                                    "Quick and fast responses for IT support."
                                </b>{" "}
                                No solution, no pay – Henderson IT Support will
                                not charge you if we cannot offer you a solution
                                to your problem. Adhoc support and perfect
                                alternative to having your own IT team. We also
                                provide remote support from our in-house Service
                                Desk for your convenience.
                            </p>

                            <br></br>
                            <p style={{ textAlign: "justify" }}>
                                <FaHandPointRight
                                    className=" me-2"
                                    style={{
                                        fontSize: "25px",
                                        color: "#fc5445",
                                    }}
                                />
                                {/* <span style={{ fontSize: '24px' }}>02. </span> */}
                                <b>
                                    "We provide IT support to suit your business
                                    needs:
                                </b>{" "}
                                whether you’re running a home office, in need of
                                fully managed IT support, or you’re an internal
                                IT team that is in need of a helping hand and
                                holiday cover, we will tailor to suit even the
                                most demanding scenarios."
                            </p>
                            <br></br>
                            <p style={{ textAlign: "justify" }}>
                                <FaHandPointRight
                                    className=" me-2"
                                    style={{
                                        fontSize: "25px",
                                        color: "#fc5445",
                                    }}
                                />
                                {/* <span style={{ fontSize: '24px' }}>03. </span> */}
                                "Our aim is to remain competitive in the market.
                                We In a world of continuous and rapid evolution,
                                it is essential for any company to rise to the
                                occasion and change use of technology.{" "}
                                <b>
                                    It’s incredibly important for us to keep
                                    learning and adapting
                                </b>{" "}
                                to refine and continually improve our processes
                                and services to suit small and medium sized
                                business ensuring best value for money."
                            </p>
                            <br></br>
                            <p style={{ textAlign: "justify" }}>
                                <FaHandPointRight
                                    className=" me-2"
                                    style={{
                                        fontSize: "25px",
                                        color: "#fc5445",
                                    }}
                                />
                                {/* <span style={{ fontSize: '24px' }}>04. </span> */}
                                "Need a second opinion before you make any big
                                changes? Moving to cloud or downsizing / reduce
                                and optimize azure subscription cost?{" "}
                                <b>
                                    We’ll tailor our level of consultancy to
                                    suit your needs,
                                </b>{" "}
                                from ad-hoc advice to a fully managed
                                implementation."
                            </p>
                            <br></br>
                            <p style={{ textAlign: "justify" }}>
                                <FaHandPointRight
                                    className=" me-2"
                                    style={{
                                        fontSize: "25px",
                                        color: "#fc5445",
                                    }}
                                />
                                {/* <span style={{ fontSize: '24px' }}>05. </span> */}
                                <b>"Quick and fast responses for IT support.</b>{" "}
                                No solution, no pay – Henderson IT Support will
                                not charge you if we cannot offer you a solution
                                to your problem. Adhoc support and perfect
                                alternative to having your own IT team. We also
                                provide remote support from our in-house Service
                                Desk for your convenience."
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChooseusSection;
