import React, { useEffect } from "react";

import BlogSection from "../../Utilities/BlogSection";
import CallToAction from "../../Utilities/CallToAction";
import ChooseusSection from "../../Utilities/ChooseusSection";
import ClientsSection from "../../Utilities/ClientsSection";
import HeroBanner from "../../Utilities/HeroBnner";
import ProjectSection from "../../Utilities/ProjectSection";
import ServiceSection from "../../Utilities/ServiceSection";
import TestimonialSection from "../../Utilities/TestimonialSection";

const HomePage = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <React.Fragment>
            <HeroBanner />
            <ServiceSection />
            {/* <ClientsSection /> */}
            <ChooseusSection />
            <ProjectSection />
            <TestimonialSection />
            <CallToAction />
            <BlogSection />
        </React.Fragment>
    );
};
export default HomePage;
