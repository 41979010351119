import React, { useEffect } from "react";
import CallToAction from "../../Utilities/CallToAction";
import PageBanner from "../../Utilities/PageBanner";
import ServiceDetailsSection from "../../Utilities/ServiceDetailsSection/ServiceDetailsSection";
import TeamSection from "../../Utilities/TeamSection";

const ServiceDetails = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return (
        <React.Fragment>
            <PageBanner pageTitle={"Service Details"} currentPage={"Service"} />

            <ServiceDetailsSection />

            <CallToAction />
            <TeamSection />
        </React.Fragment>
    );
}

export default ServiceDetails;