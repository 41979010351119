import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import contactImg from "../../../assets/images/get-touch-img.png";
const ContactSection = () => {
    return (
        <section className="contact-section py-120 rpy-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ContactInfo />
                        {/* <div>
                            <img src={contactImg} alt={"Get Touch Image"} />
                        </div> */}
                    </div>
                    {/* <div className="col-lg-6">
                        <ContactForm />
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
