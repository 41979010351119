import { Link } from "react-router-dom";
import heroShape from "../../../assets/images/hero/hero-shape.png";
import heroBg from "../../../assets//images/hero/hero-bg.jpg";

const HeroBanner = () => {
    return (
        <section
            className="hero-section"
            style={{ background: `url(${heroBg})` }}
        >
            <div className="hero-shape">
                <img src={heroShape} alt="Hero Shape Image" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="hero-content">
                            <h1>{"IT Consulting & Services."}</h1>
                            {/* <h6 style={{ color: "white" }}>
                                {
                                    "Knowing that dealing with IT is not everyone's speciality, we are here to help."
                                }
                            </h6>
                            <p>
                                {
                                    "We provide professional services to individuals and companies all across Western Australia. Our team will help you with any problems you have regarding IT in a fast and efficient manner."
                                }
                            </p> */}
                            <ul style={{ color: "white" }}>
                                <li>
                                    {
                                        "Knowing that dealing with IT is not everyone's speciality, we are here to help."
                                    }
                                </li>
                                <li>
                                    {
                                        "We provide professional services to individuals and companies all across Western Australia. Our team will help you with any problems you have regarding IT in a fast and efficient manner."
                                    }
                                </li>
                            </ul>
                            {/* <Link to={"/service-details"} className="btn theme-btn">
                                {"Find Solusion"}
                                <i className="icofont-double-right"></i>
                            </Link> */}
                            <a href="#feature" className="btn theme-btn">
                                {"Our services"}
                                <i className="icofont-double-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroBanner;
