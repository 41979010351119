import React from "react";
import { IoMdArrowDropright } from "react-icons/io";
import PageBanner from "../../Utilities/PageBanner";

import detailsImg from "../../../assets/images/project/project-details.jpg";
import { Link } from "react-router-dom";

import projectImg from "../../../assets/images/project/project-result1.jpg";
import projectImg2 from "../../../assets/images/project/project-result2.jpg";

import RelatedProjectSection from "../../Utilities/RelatedProjectSection";
import CallToAction from "../../Utilities/CallToAction";
import OnsiteISupportProjectSection from "../../Utilities/RelatedProjectSection/OnsiteItSupportProjectSection";

const ItSuppoerProjectDetails = () => {
    return (
        <React.Fragment>
            {/* <PageBanner
                pageTitle={'Project Details'}
                currentPage={'Project'}
            /> */}
            <section className="project-details-content pt-120 rpt-100 pb-70">
                <div className="container">
                    <div className="project-details-content-inner">
                        <h4>ONSITE SUPPORT SERVICES AND VISITS INCLUDE</h4>
                        <ul>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                System Integration
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Data Recovery
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                New Laptop/Desktop supply and Installation
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Windows Server Maintenance and Installation
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Network Maintenance
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Repairs and Servicing
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Hardware supply and Installation
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Health Scanning and Optimisation
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Software supply, installation maintenance and
                                Logistics
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                WiFi Network configuration and signal range
                                extending
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Home Security Video over IP/WiFi Installation
                                and configuration
                            </li>
                            <li>
                                <IoMdArrowDropright className="me-2" />
                                Business relocation
                            </li>
                        </ul>

                        <h4 style={{ marginTop: "60px" }}>
                            In addition to the possibilities of increasing
                            efficiency and the associated cost savings, we also
                            address the following areas, among others
                        </h4>
                        {/* <RelatedProjectSection /> */}
                        <OnsiteISupportProjectSection />
                    </div>
                </div>
            </section>
            <CallToAction extraClass={"bg-white"} />
        </React.Fragment>
    );
};
export default ItSuppoerProjectDetails;
