import React, { useEffect }from "react";
import ContactSection from "../../Utilities/ContactSection";
import PageBanner from "../../Utilities/PageBanner";

const ContactUs = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (

        <React.Fragment>

            <PageBanner
                pageTitle={'Contact Us'}
                currentPage={'Contact'}
            />

            <ContactSection />



        </React.Fragment>

    );
}

export default ContactUs;