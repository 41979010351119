import { Link } from "react-router-dom";
// import thaumb1 from '../../../assets/images/partners/partner-logo1.png';
// import thaumb2 from '../../../assets/images/partners/partner-logo2.png';
// import thaumb3 from '../../../assets/images/partners/partner-logo3.png';
// import thaumb4 from '../../../assets/images/partners/partner-logo4.png';
// import thaumb5 from '../../../assets/images/partners/partner-logo1.png';
// import thaumb6 from '../../../assets/images/partners/partner-logo2.png';

import thaumb1 from "../../../assets/images/partners/partner-logo11.png";
import thaumb2 from "../../../assets/images/partners/partner-logo22.png";
import thaumb3 from "../../../assets/images/partners/partner-logo33.png";
import thaumb4 from "../../../assets/images/partners/partner-logo44.png";
import thaumb5 from "../../../assets/images/partners/partner-logo55.png";
import thaumb6 from "../../../assets/images/partners/partner-logo66.png";

const clients = [
    {
        thumb: thaumb1,
        title: "Hard Drive",
    },
    {
        thumb: thaumb2,
        title: "SSD",
    },
    {
        thumb: thaumb3,
        title: "Server",
    },
    {
        thumb: thaumb4,
        title: "Tape",
    },
    {
        thumb: thaumb5,
        title: "Desktop",
    },
    {
        thumb: thaumb6,
        title: "Laptop",
    },
];

const DisasterClients = () => {
    return (
        <section className="partner-section">
            <div style={{ textAlign: "center" }}>
                <h4>Data Recovery</h4>
            </div>
            <div style={{ textAlign: "center" }}>
                <h6>What can we recover?</h6>
            </div>
            <div className="container">
                <div className="partners-group">
                    <div className="row">
                        {clients.map((data, index) => (
                            <div className="col-md-2 col-3" key={index}>
                                <div className="partner-logo">
                                    <Link to={"#"}>
                                        <img
                                            src={data.thumb}
                                            alt={data.title}
                                        />
                                    </Link>
                                    {data.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DisasterClients;
