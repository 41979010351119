import React, { useEffect } from "react";
import CallToAction from "../../Utilities/CallToAction";
import PageBanner from "../../Utilities/PageBanner";
import ServiceDetailsSection from "../../Utilities/ServiceDetailsSection/ServiceDetailsSection";
import TeamSection from "../../Utilities/TeamSection";
import CloudSolutionDetails from "../../Utilities/ServiceDetailsSection/CloudSolutionDetails";
import CloudServiceSection from "../../Utilities/ServiceSection/CloudServiceSection";
import CloudBanner from "../../Utilities/PageBanner/CloudBanner";

const CloudSolution = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <React.Fragment>
            {/* <PageBanner
                pageTitle={'Cloud Solution'}
                currentPage={'Cloud'}
            /> */}
            <CloudBanner
                pageTitle={"Cloud Solution"}
                currentPage={"Cloud Solutions"}
            />

            {/* <CloudSolutionDetails /> */}
            <CloudServiceSection />

            <CallToAction />
            {/* <TeamSection/> */}
        </React.Fragment>
    );
};

export default CloudSolution;
